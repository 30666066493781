<!--
 * @Descripttion: 搜索页面
 * @version: 1.0.0
 * @Author: huchongyuan
 * @Date: 2021-04-20 22:16:18
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-04-21 22:11:06
-->
<template>
    <div id="Search">
        <div class="searchBg"></div>
        <div class="searchForm">
            <Input v-model="searchStr" placeholder="请输入查询内容">
              <Select v-model="standClass" slot="prepend" style="width: 80px">
                  <Option v-for="opt in options" :value="opt.statusNo" :key="opt.statusNo">{{opt.statusDesc}}</Option>
              </Select>
              <Button slot="append" type="primary" @click="search">查询一下</Button>
            </Input>
             <!-- <Input v-model="searchText" search enter-button="查询一下" @on-search="search" placeholder="请输入查询内容" /> -->
        </div>
    </div>
</template>
<script>
export default {
  name: 'Search',
  data() {
    return {
      searchStr:"",
      standClass:"-1"
    };
  },
   computed:{
        options:()=>{
            let defaultResult =  [{"statusNo":"-1","statusDesc":"全部"}];
            if(sessionStorage.getItem("DD003")){
                var result = JSON.parse(sessionStorage.getItem("DD003"));
                return [...defaultResult,...result];
            }
            return defaultResult;
        }
 },
  methods: {
    search(){
      if(!this.searchStr==""){
         this.$router.push({
            "name":"NormAdvancedQuery",
            "params":{
              "searchStr":this.searchStr,
              "standClass":this.standClass,
              "query":1
            }
          });
      }else{
        this.$Message.warning('请输入查询内容');
      }
    }
  },
};
</script>
<style lang="less">
#Search{
    width:654px;
    height:314px;
    position:fixed;
    top:80px;
    left:50%;
    margin-left:-327px;
    .searchBg{
        width:100%;
        height:260px;
        background-image: url('../../public/searchBg.png');
        background-position: center center;
        background-size:100%;
        background-repeat: no-repeat;        
    }
}
#Search input{
    height:50px;
}
#Search .ivu-input-group-append{
  background-color: #2d8cf0;
  color:#fff;
}
#Search .ivu-input-group-prepend{
  width:110px
}
#Search .ivu-select{
  width:100px !important;
}
</style>
